export default function useDynamicHeadline() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const contentStore = useContentStore();
  const getRouteBaseName = useRouteBaseName();
  const route = useRoute();
  const seoStore = useSeoStore();
  const { $pgettext, interpolate } = useGettext();

  /********************
   * REFS & VARS       *
   ********************/
  const dynamicHeadline = computed(() => {
    const h1 = contentStore.h1;
    const routeBaseName = getRouteBaseName(route);
    const metaAtRouteName = routeBaseName && metaData[routeBaseName];
    const dynamicMetaParam = { ...seoStore.dynamicMetaParam };

    if (dynamicMetaParam.searchTerm) {
      dynamicMetaParam.searchTerm = htmlEscape(dynamicMetaParam.searchTerm);
    }

    if (h1) {
      return dynamicMetaParam ? interpolate(h1, dynamicMetaParam, true) : h1;
    }

    if (!metaAtRouteName) {
      return h1;
    }

    const translated
      = metaAtRouteName.defaultH1 && $pgettext(metaAtRouteName.defaultH1?.ctx, metaAtRouteName.defaultH1?.key);
    if (dynamicMetaParam && typeof dynamicMetaParam === 'object') {
      if (dynamicMetaParam.searchTerm === '' && !route.query.area) {
        return $pgettext(
          'Fallback search headline for no search term',
          '<span class="text-medium">Campsites</span> in Europe',
        );
      } else if (dynamicMetaParam.searchTerm === '' && route.query.area) {
        return $pgettext(
          'Fallback search headline for no search term and map area',
          '<span class="text-medium">Campsites</span> in map section',
        );
      }
      return interpolate(translated, dynamicMetaParam, true);
    }

    if (dynamicMetaParam && typeof seoStore.dynamicMetaParam !== 'object') {
      return interpolate(translated, { dynamicParam: htmlEscape(dynamicMetaParam) }, true);
    }

    return translated;
  });

  return {
    dynamicHeadline,
  };
}
